import React, {Component} from 'react';
import {PageTpl, Container, PageHeader, ImageBlurhash} from '../components';
import { useRouter } from '../store/router';
import { useNewsStore } from '../store/news'
import { useIsMobile } from '../utils/use-is-mobile';

class NewsFeedComp extends Component {
    onClick = (n, index) => {
        const {router} = this.props;
        const url = `/news/${n.id}`;
        router.push(url, {prevPath: location.pathname})
    };

    renderHeader = (props) => {
        const {title} = props;
        return (
            <Container wrapperClassName="page-header">
                <div className="title" dangerouslySetInnerHTML={{__html: title}} />
                {/*<input className="sortby" placeholder="Sort by date" />*/}
            </Container>
        );
    };

    renderItem = (n, index) => {
        const {isMobile} = this.props
        // TODO: TEST EXCERPT CHANGES
        const {title, tags, pageBuilder, excerpt, page_author} = n;
        const headerModule = pageBuilder.find((m) => m.acfFcLayout === 'page_header_module');
        //const excerptModule = pageBuilder.find(m => m.acfFcLayout === 'Excerpt_Module');
       

        return (
            <div
                className="news-card"
                onClick={() => {
                    this.onClick(n, index);
                }}
                key={index}
            >
                <ImageBlurhash className="image" src={headerModule.backgroundImage} alt="" id={`image-${index}`} height={isMobile ? 'auto' : null}/>
                <div className="title">{title}</div>
                <div className="tags">{tags}</div>
                <div className="excerpt">{excerpt /*excerptModule.excerpt*/}</div>
                <button className="btn-black">Learn more</button>
            </div>
        );
    };

    render() {
        const {news} = this.props;
        return (
            <PageTpl className="news-feed-page">
                <PageHeader title="News" titleComponent={this.renderHeader} />
                <Container wrapperClassName="feed-container-wrapper">{news && news.map(this.renderItem)}</Container>
            </PageTpl>
        );
    }
}

export default function NewsFeed() {
    const newsStore = useNewsStore()
    const router = useRouter()
    const isMobile = useIsMobile
    //console.log(newsStore.news);
    return <NewsFeedComp news={newsStore.news} router={router} isMobile={isMobile}/>
}
