export { default } from '../src/App'
import { transformPages } from '../src/store/pages'
import { transformCaseStudies } from '../src/store/caseStudies'
import { transformNews } from '../src/store/news'
import { routes } from '../src/services/routes'
import fs from 'fs'

const getPagesCached = async () => {
    const r = JSON.parse(fs.readFileSync('./public/derived/pages.json', 'utf8'))
    return transformPages(r.data)
}

const getCaseStudiesCached = async () => {
    const r = JSON.parse(fs.readFileSync('./public/derived/case-studies.json', 'utf8'))
    r.data.forEach((t) => {
        t.url = `/case-study/${t.id}`
    })
    return transformCaseStudies(r.data)
}

const getNewsCached = async () => {
    const r = JSON.parse(fs.readFileSync('./public/derived/news.json', 'utf8'))
    r.data.forEach((t) => {
        t.url = `/news/${t.id}`
    })
    return transformNews(r.data)
}

export async function getStaticProps(props) {
    const isCaseStudy = (props.params.index?.length >= 2) && props.params.index?.[0] == 'case-study'
    const isNews = (props.params.index?.length >= 2) && props.params.index?.[0] == 'news'

    let outProps = {
        ...props.params
    }

    if (isCaseStudy) {
        const caseStudies = await getCaseStudiesCached()
        const id = props.params.index?.[1]
        const caseStudy = caseStudies?.find(p => p.id == id)
        if (caseStudy) {
            outProps.caseStudy = caseStudy
        }
    } else if (isNews) {
        const newsList = await getNewsCached()
        const id = props.params.index?.[1]
        const news = newsList?.find(p => p.id == id)
        if (news) {
            outProps.news = news
        }
    } else {
        const pages = await getPagesCached()
        const pathname = `/${props.params.index?.[0] || ''}`
        const page = pages?.find(p => p.url == pathname)
        if (page) {
            outProps.page = page
        }
    }

    //const ogImage = await getOgImage(`/phiilu.com/post?title=${title}&url=${process.env.BASE_URL}/about-me`)

    return { props: outProps }
}
  
// This function gets called at build time - https://nextjs.org/docs/api-reference/data-fetching/get-static-paths
export async function getStaticPaths() {
    let pages = await getPagesCached()
    let caseStudies = await getCaseStudiesCached()
    let news = await getNewsCached()
    let pathnames = new Set()   

    const visitPage = (page) => {
        if (page.url) {
            pathnames.add(page.url)
        }
    }

    const visitRoute = (route) => {
        if (route.pathname && !route.pathname.endsWith(':id') && route.pathname.startsWith('/')) {
            pathnames.add(route.pathname)
        }
        if (route.children) {
            route.children.forEach(visitRoute)
        }
    }

    // hard-coded routes
    routes.forEach(visitRoute)

    // generate pages for pages
    pages.forEach(visitPage)

    // generate pages for case studies
    caseStudies.forEach(visitPage)

    // news pages
    news.forEach(visitPage)

    // convert pathnames into a list of paths - the routes we want to render out
    let paths = []
    Array.from(pathnames).forEach(pathname => {
        if (!pathname) {
            return
        }
        const splits = pathname.replace('/', '').split('/').filter(s => !!s)
        // generate 2 paths, the actual page and then a directory with an index - the clean version
        paths.push({ params: { index: [...splits, 'index'] } })
        paths.push({ params: { index: splits } })
    })

    // We'll pre-render only these paths at build time.
    // { fallback: false } means other routes should 404.
    return { paths, fallback: false }
}
